<script setup>
const store = useCommonStore();
const gtm = useGtm();

const activeItem = computed(() => store.activeItem);
const featuredItems = computed(() => store.items);

const { setActiveItem, setTypeVariant } = store;

const { locale, t } = useI18n();

let scrollTimeout;

const handleScroll = () => {
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    const scrollPosition = window.scrollY;

    for (let i = 0; i < featuredItems.value.length; i++) {
      const item = featuredItems.value[i];
      const element = document.querySelector(`.featured-items__item:nth-child(${i + 1})`);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 250 && rect.bottom >= 250) {
          setActiveItem(i);
          setTypeVariant(item.item.logo_variant);
          break;
        }
      }
    }
  }, 15);
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const featureClick = (type, eventId) => {
  track(type, eventId);
};

const track = (type, eventId) => {
  if (type == 'Event') {
    gtm.trackEvent({ event: 'eventDetail_select', eventId: eventId, url: window.location.pathname });
  }
};

const getImage = (item, key) => {
  if (item.__typename === 'Event') {
    return item.image ? item.image.url : `/img/dummy/ngp.png`;
  } else {
    return item.news_image ? item.news_image : `/img/dummy/ngp.png`;
  }
};

const setAttributesOnHover = (number, variant) => {
  setActiveItem(number);
  setTypeVariant(variant);
};
</script>

<template>
  <div class="featured-items">
    <nuxt-link
      v-for="({ item }, key) in featuredItems"
      @click.native="featureClick(item.__typename, item.id)"
      :to="
        localePath({
          name: item.__typename === 'Event' ? 'event-id-slug' : 'about-news-slug',
          params: { slug: item.slug[locale], id: item.__typename === 'Event' ? item.id : null },
        })
      "
      :class="{ 'is-active': key == activeItem }"
      class="featured-items__item"
    >
      <div class="featured-items__item-inner" data-heading-hover @mouseover="setAttributesOnHover(key, item.logo_variant)">
        <picture>
          <source :srcset="useGetImageUrl(getImage(item), 600, null, 'webp')" type="image/webp" />
          <img :src="useGetImageUrl(getImage(item), 600, null, 'jpg')" :alt="item.name" class="featured-items__image" loading="lazy" />
        </picture>

        <div class="featured-items__type">
          <div v-if="item.categories && Object.keys(item.categories).length > 0">
            <span v-for="(category, key) in item.categories" :key="key">{{ category.name }}<span v-if="item.categories.length - 1 > key">, </span> </span>
          </div>
          <div v-else>
            <span>
              {{ item.__typename === 'Event' ? t(`global.event-types.${item.type.toUpperCase()}`) : t('global.news-item') }}
            </span>
          </div>
        </div>
        <div class="featured-items__title">
          <h2 class="heading">
            <span class="heading__link" v-html="item.name" />
          </h2>
        </div>
        <div v-if="item.__typename === 'Page' && item.publish_date" class="featured-items__meta">
          {{ item.publish_date.timestamp }}
        </div>
        <div v-if="item.__typename === 'Event' && item.dates.length" class="featured-items__meta">
          <div v-if="item.type !== 'exposition'">
            {{ useDateRange(item.dates) }}
          </div>
          <div v-if="item.buildings" class="featured-items__location">
            <span>
              {{ item.buildings.map((item) => item.name).join(', ') }}
            </span>
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
.featured-items {
  $s: &;
  margin-bottom: 75px;
  margin-left: -20px;
  margin-right: -20px;

  @include mq($lg) {
    margin-left: 0;
    margin-right: 0;
    max-width: 600px;
  }

  .home & {
    margin-left: 0;
    margin-right: 0;
  }

  &__item {
    display: block;
    padding-bottom: 20px;
    padding-top: 20px;
    cursor: pointer;
    text-decoration: none;

    @include mq($lg) {
      padding-bottom: 25px;
      padding-top: 25px;
      padding-right: 50px;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    // &[data-hs-active],
    &:hover,
    &.is-active,
    &:focus {
      #{$s}__item-inner {
        transform: translateX(0px) !important;
      }
    }
  }

  &__item-inner {
    transition: transform 0.3s $ease-out-quad;

    @include mq($lg) {
      transform: translateX(40px);
    }
  }

  &:hover,
  &:focus-within {
    #{$s}__item-inner {
      @include mq($lg) {
        transform: translateX(40px) !important;

        &:focus {
          transform: translateX(40px) !important;
        }
      }
    }

    #{$s}__item {
      &:hover,
      &:focus {
        #{$s}__item-inner {
          @include mq($lg) {
            transform: translateX(0px) !important;
          }
        }
      }
    }
  }

  &__image {
    width: 100%;
    max-width: #{$xs}px;
    margin-bottom: 20px;

    .home & {
      max-width: 100%;
      height: 100vw;
      object-fit: cover;
    }

    @include mq($lg) {
      display: none;
    }
  }

  &__type {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
    font-weight: $font-weight-medium;
    text-transform: uppercase;

    .home & {
      margin-bottom: 5px;
      padding-left: 20px;
      padding-right: 20px;

      @include mq($lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__title {
    margin-bottom: 0.3em;
    letter-spacing: -1px;

    .home & {
      padding-left: 20px;
      padding-right: 20px;

      @include mq($lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__meta {
    font-size: 20px;
    margin-top: 0.3em;
    line-height: 1.375;

    .home & {
      padding-left: 20px;
      padding-right: 20px;

      @include mq($lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__date {
    margin-bottom: 0.2em;
  }

  &__button {
    margin-left: 20px;
    margin-right: 20px;

    @include mq($lg) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &__location {
  }
}

.button--featured {
  margin-bottom: 50px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;

  @include mq($lg) {
    margin: 0 !important;
  }
}
</style>
