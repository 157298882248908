<script setup>
import { ref } from 'vue';
const { locale, t } = useI18n();
const props = defineProps({
  news: {
    type: Array,
  },
});

const words = ref('');
const animationDuration = ref(6000);
const animationPlayState = ref('running');

onMounted(() => {
  const marquee = document.querySelector('.news-bar__marquee ul');
  props.news.forEach((wrap) => {
    words.value += words.value == '' ? wrap.name[locale.value] : ' ' + wrap.name[locale.value];
  });
  animationDuration.value = (words.value.split(' ').length / 3.333) * 1000 * 2;
});

const over = () => {
  animationPlayState.value = 'paused';
};
const leave = () => {
  animationPlayState.value = 'running';
};
</script>

<template>
  <div v-if="news" class="news-bar">
    <div class="news-bar__marquee">
      <ul
        ref="marquee"
        class="news-bar__list"
        :style="{
          'animation-duration': animationDuration + 'ms',
          'animation-play-state': animationPlayState,
        }"
        @mouseover="over"
        @focus="over"
        @mouseleave="leave"
        @blur="leave"
      >
        <li v-for="(item, key) in news" :key="key">
          <NuxtLink :to="localePath({ name: 'about-news-slug', params: { slug: item.slug[locale] } })">
            {{ item.name[locale] }}
          </NuxtLink>
        </li>
        <li>
          <ul>
            <template v-for="index in 10">
              <li v-for="(item, key) in news" :key="'news-replicant-' + index + key">
                <NuxtLink :to="localePath({ name: 'about-news-slug', params: { slug: item.slug[locale] } })">
                  {{ item.name[locale] }}
                </NuxtLink>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>

    <nuxt-link :to="localePath({ name: 'about-news' })" class="news-bar__all">
      {{ t('global.news.all') }}
    </nuxt-link>
  </div>
</template>

<style lang="scss" scoped>
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.news-bar {
  display: flex;
  height: 19px;
  line-height: 19px;
  margin-bottom: 115px;
  overflow: hidden;
  width: 100%;
  @media (max-width: 991px) {
    display: none;
  }
  &__marquee {
    //background: pink;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  ul {
    animation-iteration-count: infinite;
    animation-name: marquee;
    animation-timing-function: linear;
    display: flex;
    flex-flow: row;
    position: absolute;
    white-space: nowrap;

    ul {
      left: 100%;
      position: absolute;
      top: 0;
    }

    li {
      flex-shrink: 0;
      margin-left: 10px;
      padding-left: 16px;
      position: relative;

      &:first-child {
      }
      &:last-child {
        left: 100%;
        position: absolute;
      }

      &:before {
        background: $black;
        border-radius: 6px;
        content: '';
        display: inline-block;
        height: 6px;
        left: 0;
        margin-top: -3px;
        position: absolute;
        top: 50%;
        vertical-align: middle;
        width: 6px;
      }
      &:after {
        left: 26px;
      }
    }
  }

  .news-bar__list {
    > li {
      &:last-child {
        margin-left: 0;
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  a {
    position: relative;

    &:after {
      background: $black;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transform-origin: 0 100%;
      transition: transform 0.3s;
      width: 100%;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  &__all {
    margin-left: 20px;
    position: relative;

    &:before {
      background: $black;
      content: '';
      display: block;
      height: 100%;
      margin-right: 10px;
      position: absolute;
      right: 100%;
      top: 0;
      width: 1px;
    }
  }
}
</style>
